
.modal {
  display: none; 
  position: fixed; 
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); 
  background-color: rgba(0,0,0,0.4); 
  animation: fadeIn 0.5s ease-in-out; 
}


.modal-content {
  background-color: #fefefe;
  margin: 15% auto; 
  padding: 20px;
  border: 1px solid #888;
  width: 80%; 
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.5s ease-in-out; 
}

/* Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.paymenyForm{
  width: 80%;
  margin: auto;
}
.paymenyForm input{
  padding: 15px;
  width: 100%;
 
}
.paymenyForm button{
  background-color: #fff !important;
  border: none;
  border: 1px solid var(--bg-light);
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  margin-top: 10px;
  width: 100%;
 
}
.success{
  border: 2px dashed #888888ed;
  border-radius: 100px;
  margin: 0px auto;
  margin-right: 100px !important;
  height: 90px;
  width: 90px;
  display: flex;
  align-items: center !important;
  justify-content: center;
 
}
.fa-check{
  font-weight: 700;
  color: #888888ed;
  font-size: 35px;

 
}
.loader {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 50%;

  width: 50px;
  height: 50px;

  display: block; /* Hidden by default */
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }
  to {
    transform: translateY(0);
  }
}
