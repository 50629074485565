
:root {
  --bg-color: #1E1E1E;
  --text-color: #FFFFFF;
  --accent-color: #4ECDC4;
  --neutral-color: #CCCCCC;
  --bg-light:#333;
  --dark-color:rgba(255, 255, 255, 0.8)
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
}

header {
  text-align: center;
  padding: 20px 0;
}

h1 {
  font-size: 36px;
}

.login {
  text-align: center;
  padding: 20px 0;
}

.login input {
  padding: 10px;
  margin: 0 5px;
  border-radius: 6px;
  border: 1px solid var(--neutral-color);
  outline: none;
  width: 200px;
}

.login button {
  background-color: #333;
  color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login button:hover {
  background-color: #3EA9A2;
}

.packages {
  text-align: center;
  padding: 50px 0;
}

.advert {
  color: var(--accent-color);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.package-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.package {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  margin: 0 10px 20px;
  border: 1px solid var(--accent-color);
  height: 250px !important;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

ul, ol {
  list-style: none;
}
.package ul > li{
padding: 1px;
font-size: 13px;

}
li,h1,h2,h3,h4,h5,h6,p{
  color: var(--dark-color);
}

.package:hover {
  transform: translateY(-5px);
}

.package h3 {
  margin-bottom: 10px;

}

.package p {
  margin-bottom: 1px;
}

.package button {
  background-color: var(--accent-color);
  color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 0px;
  
}

.package button:hover {
  background-color: #3EA9A2;
}

footer {
background-color: var(--bg-light);
  text-align: center;
  padding: 20px 0;
}

footer a {
  color: var(--text-color);
  text-decoration: none;
  margin: 0 10px;
}

@media screen and (max-width: 768px) {
  .package {
      width: calc(50% - 20px);
  }
  .modal-content {

    margin-top:100px !important;
  }
}
@media screen and (min-width: 1024px) {
  .package {
    width: calc(28.33% - 20px);
    height: 350px !important;
  }
  .packages{
    width: 80%;
    margin: 0 auto; /* Add this to horizontally center the container */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
  }
  .package-container{
    padding: 30px;
  }
  .modal-content{
    width: 50% !important;
  }
}